import React from 'react'
import styled from 'styled-components'
import { BlockContent } from '../BlockContent'
import { H2 } from '../atoms/typo/typo'
import { Stars } from './Stars'
import { colors } from '../GlobalStyles/vars/colors'

const Container = styled.div`
  padding-top: 1em;

  h2 {
    margin-bottom: 8px;
  }

  span {
    color: ${colors.primary};
    padding-left: 6%;
  }
`

interface Props {
  name: string
  body: React.ReactNode
  date?: string
  rating?: number
}

export const Review = ({ name, body, rating, date }: Props): JSX.Element => {
  return (
    <Container>
      <H2>{name}</H2>
      <span>{date}</span>
      {rating && <Stars rating={rating} />}
      <BlockContent body={body} />
    </Container>
  )
}
