import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout/Layout'
import { SEO } from '../helpers/SEO'
import styled from 'styled-components'
import { Review } from '../components/Review/Review'
import { device } from '../components/GlobalStyles/helpers/device'
import { H1 } from '../components/atoms/typo/typo'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'
import { HeroImage } from '../components/HeroImage'

const ReviewsWrapper = styled.div`
  margin: 15px;

  @media ${device.tablet} {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 35px;
  }
`

interface Props {
  data: {
    allSanityReview: {
      nodes: [
        {
          _rawBody: React.ReactNode
          _id: string
          name: string
          date: string
          rating: number
        },
      ]
    }
    file: {
      childImageSharp: {
        fluid: GatsbyFluidImageProps
      }
    }
  }
}

const ReviewsPage = ({ data }: Props): JSX.Element => {
  const reviews = data.allSanityReview.nodes

  return (
    <Layout>
      <SEO
        title="Reviews"
        description="Read reviews from the great clients who have learned to fly at Queen City Flying Service!"
        location="Cincinnati, OH"
      />

      <HeroImage fluid={data.file.childImageSharp.fluid} alt="The old terminal at the Lunken Airport" />

      <H1>Read what our happy clients have to say!</H1>

      <ReviewsWrapper>
        {reviews.map((review) => {
          return (
            <Review
              body={review._rawBody}
              name={review.name}
              date={review.date}
              key={review._id}
              rating={review.rating}
            />
          )
        })}
      </ReviewsWrapper>
    </Layout>
  )
}

export default ReviewsPage

export const query = graphql`
  {
    allSanityReview(sort: { order: DESC, fields: date }, limit: 10) {
      nodes {
        _rawBody
        _id
        name
        rating: stars
        date(formatString: "MMM YYYY")
      }
    }
    file(relativePath: { eq: "HY_1107.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
