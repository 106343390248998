import React from 'react'
import styled from 'styled-components'
import { BsFillStarFill } from 'react-icons/all'
import { colors } from '../GlobalStyles/vars/colors'

const Container = styled.div`
  color: ${colors.primary};
  padding-left: 6%;
`

interface Props {
  rating: number
}

export const Stars = ({ rating }: Props): JSX.Element => {
  const items = []

  for (let i = rating; i > 0; i--) {
    items.push(<BsFillStarFill />)
  }

  return <Container>{items}</Container>
}
